// roleperm

<template>
	<div>
	<v-card dark tile flat>
		<v-toolbar
			color="indigo lighten-1"
			dark
			flat tile
		>

			<v-toolbar-title>RolePermissions</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn class="green" :disabled="!isDirty" @click="onSubmit">
				save
			</v-btn>

			<template v-slot:extension>
				<v-tabs
					v-model="tab"
					align-with-title
					color="indigo darken-4"
					background-color="transparent"
					slider-color="indigo darken-4"
					dark
					next-icon="mdi-arrow-right-bold-box-outline"
					prev-icon="mdi-arrow-left-bold-box-outline"
					show-arrows show-arrows-on-hover
				>
					<v-tab v-for="(item) in items" :key="item.id">
						{{ item.name }}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-tabs-items v-model="tab">
			<v-tab-item v-for="(item) in items" :key="item.id">
				<v-card flat tile dark color="grey darken-2" style="max-height: 700px" class="overflow-y-auto">
      				<template v-for="(role) in item.roles">
	  					<v-list-item :key="role.id">
        					<v-list-item-content>
								<span class="subheading grey darken-1 pa-1 black--text">{{role.name + ' ' + role.id}}</span>
								<v-chip-group v-model="selection" multiple column
									:mandatory="false" active-class="indigo lighten-2 black--text">
									<v-btn text @click="onAll(role.perms)">all</v-btn>
									<v-btn text @click="onNone(role.perms)">none</v-btn><v-divider class="mx-4" vertical></v-divider>
									<v-chip v-for="(perm) in role.perms" :key="perm.id" :value="perm.id"
									 		@click="onTogglePerm()">
										<v-avatar v-if="isActive(perm.id)"><v-icon>check_circle_outline</v-icon></v-avatar>
										{{ perm.name }}
									</v-chip>
								</v-chip-group>
        					</v-list-item-content>
      					</v-list-item>
	  					<v-divider :key="role.name"></v-divider>
      				</template>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
	<v-overlay absolute :value="loading">
		<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
	</v-overlay>
	<v-snackbar v-model="snack.active" :color="snack.type"> {{ snack.text }}
		<v-btn text dark @click="snack.active = false">Ok</v-btn>
	</v-snackbar>
	</div>
</template>

<script>
	//import X from './x'
	import { mapGetters } from 'vuex'
	export default {
	  	name: 'roleperm',
		components: {},
	  	props: {
	  		item: {
	  			type: Object,
	  			default () {
	  				return {}
	  			}
	  		},
	  	},
		data() {
			return {
				tab: 0,
				items: [],
				selection: [],
				msgError: '',
				isDirty: false,
				loading: false,
				snack: { active: false, type: 'success', text: 'saved',	},
            }
        },
		computed: {
			...mapGetters({
				roleperms: 'roleperms/getRolePerms',
			}),
		},
		methods: {
			makeData() {
				const dtables = [...new Set(this.roleperms.map(item => item.dtable))];
				const droles = [...new Set(this.roleperms.map(item => item.role))];
				const dperms = [...new Set(this.roleperms.map(item => item.perm))];
				//console.log(dperms)
				const xitems = []
				let counter = 0
				dtables.forEach(dtable => {
					const roles = []
					droles.forEach(role => {
						const perms = []
						dperms.forEach(perm => {
							let data = this.roleperms.find(x =>
							 (x.dtable === dtable && x.role === role && x.perm === perm));
							if(data) perms.push({ 'id': data.id, 'name': perm, 'selected': data.selected })
						})
						roles.push({ 'id': (++counter), 'name': role, 'perms': perms })
					})
					xitems.push({ 'id': (++counter), 'name': dtable, 'roles': roles })
				})
				this.selection = []
				this.roleperms.forEach(perm => {
					if(perm.selected) this.selection.push(perm.id)
				})
				this.items = xitems
				//console.log(JSON.stringify(this.items))
			},
			fetchData () {
				this.loading = true
				let self = this
				this.$store.dispatch('roleperms/getRolePerms')
				.then((response) => {
					this.makeData()
					self.loading = false
				})
				.catch((error) => {
					console.log(error)
					self.loading = false
				});
			},
            onTogglePerm() {
				this.isDirty = true            
            },
            isActive(id) {
				if(this.selection.includes(id)) return true
				return false
			},
			onSubmit() {
				this.isDirty = false            
				this.loading = true
				const xroleperms = []
				this.selection.forEach(perm => {
					let roleperm = this.roleperms.find(x => x.id === perm);
					xroleperms.push({ 'role_id': roleperm.role_id, 'permission_id': roleperm.permission_id, 'selected': 1 })
				})
		        const data = xroleperms
	      		this.$store.dispatch('roleperms/updateRolePerms', data)
	      		.then((response) => {
					this.loading = false
        			this.snack = {active: true, type: 'success', text: 'saved'}
      			})
		    	.catch((error) => {
					this.loading = false
					this.isDirty = true            
        			this.snack = {active: true, type: 'error', text: 'something went wrong'}
		    		console.log('submit->error')
			    	console.log(error)
			    	this.msgError(error.response.data)
			    });
			},
			onAll(roleperms) {
				const perms = roleperms.map(x => x.id)
				this.selection = this.selection.filter(x => !perms.includes( x )).concat(perms)
				this.isDirty = true            
			},
			onNone(roleperms) {
				const perms = roleperms.map(x => x.id)
				this.selection = this.selection.filter(x => !perms.includes( x ))
				this.isDirty = true            
			},
        },
		mounted() {
			this.fetchData ()
		},
	  	watch: {},
    }
</script>

<style>
</style>